import React, { useState, useRef } from "react";
import debounce from "lodash.debounce";
import { Auth, API } from "aws-amplify";
import {
    createWorkout as createWorkoutMutation,
    updateWorkout as updateWorkoutMutation,
    createComment as createCommentMutation
} from "../graphql/mutations";
import {
    Div,
    Text,
    Row,
    Col,
    Textarea,
    Input,
    Icon,
    Button,
    Collapse
} from "atomize";
import moment from 'moment';
import ConditionalInput from '../components/conditionalInput'

const WorkoutTab = ({ workoutInput, isAdmin, userBeingViewed }) => {

    const now = moment().format().split('T')[0];
    const [workout, setWorkout] = useState(workoutInput);
    const [isSaved, setIsSaved] = useState(undefined);
    const [showCollapse, setShowCollapse] = useState(false);
    const nodeRef = useRef(null);

    async function publishComment(event) {
        if (event.target.elements.comment.value) {
            const currentUser = await Auth.currentUserInfo();
            const timestamp = Date.now().toString();
            const comment = {
                owner: userBeingViewed.name,
                batch: event.target.elements.batch.value + "#" + timestamp,
                commenter: currentUser.username,
                content: event.target.elements.comment.value,
                timestamp
            }
            workout.comments.push(comment);
            setWorkout({...workout});
            
            setIsSaved(false);
            const promise = API.graphql({
                variables: {
                    input: {
                        owner: userBeingViewed.name,
                        batch: event.target.elements.batch.value + "#" + timestamp,
                        commenter: currentUser.username,
                        content: event.target.elements.comment.value,
                        timestamp
                    }
                },
                authMode: "AMAZON_COGNITO_USER_POOLS",
                query: createCommentMutation
            });
            event.target.reset();
            await promise;
            setIsSaved(true);
            nodeRef.current.onEntering();
        }
    }

    async function upsertWorkout(field, value) {
        console.log(`Upserting: ${field}=${value} for ${workout.date}`);

        const upsert = {
            variables: {
                input: {
                    owner: userBeingViewed.name,
                    batch: workout.batch,
                    date: workout.date,
                    [field]: value
                }
            },
            authMode: "AMAZON_COGNITO_USER_POOLS"
        }
    
        // try to update and create if failed
        setIsSaved(false);
        try {
            await API.graphql({ ...upsert, query: updateWorkoutMutation });
        } catch (e) {
            if (e.errors.find((error) => error.errorType == 'DynamoDB:ConditionalCheckFailedException' || error.errorType == 'Unauthorized') !== undefined) {
                await API.graphql({ ...upsert, query: createWorkoutMutation });
            } else {
                throw e;
            }
        }
        setIsSaved(true);
    }

    return (
        <Col size={7}>
            <Div border="1px solid" borderColor="gray300" /* {now == workout.date ? "success800" : "gray100"} */ bg="white" /* {now == workout.date ? "success200" : "white"} */ m={{ b: "1rem" }} p=".5rem" shadow={3} rounded="lg" d="block" >
                <Div d="flex" w="100%" justify="space-between">
                    <Div d="flex">
                    <Text p={{ l: ".5rem" }} textSize="heading" textColor="brand300">
                        {moment(workout.date).format("ddd,")} 
                    </Text>
                    <Text p={{ l: ".25rem", b: ".25rem" }} textSize="heading" textColor="brand100">
                        {moment(workout.date).format(" M/D")} 
                    </Text>
                    </Div>
                    <Div d="flex">
                        <Button onClick={() => setShowCollapse(!showCollapse)} bg="white" rounded="circle" h="2.25rem" w="2.25rem" border="1px solid" borderColor="gray400" suffix = {
                            showCollapse ? 
                            <Icon name="Minus" size="20px" color="gray900" /> : 
                            <Icon name="Plus" size="20px" color="gray900" />
                        }/>
                    </Div>
                </Div>
                <Div d="flex" justify="space-between" onClick={() => setShowCollapse(!showCollapse)}>
                    <Div d="flex" flexGrow="2">
                    {
                        isAdmin ?
                        <Div w="95%"><Input  readOnly={!isAdmin} onClick={(e) => {e.stopPropagation();}} onChange={debounce((e) => isAdmin ? upsertWorkout("title", e.target.value) : null, 300)} defaultValue={(workout.isPublished || isAdmin) ? workout.title : ""} p={{ l: ".5rem" }} textSize="1.3em" textColor="brand300" m={{ b: ".25rem" }} placeholder="Workout Title" /></Div>
                        : <Text p={{ l: ".5rem" }} textSize="1.3em" textColor={workout.isPublished ? "brand300" : "gray600"} m={{ b: ".25rem" }} >{workout.isPublished ? workout.title : "Workout to come!"}</Text>
                    }
                    </Div>
                    <Div d="flex">
                        {isSaved === true ?
                                <Button h="1.75rem" bg="info300" textColor="info800" textSize= "1rem" rounded="lg" m={{ r: ".25rem", l:"0rem", b:".25rem" }} p={{ x: ".75rem", y: "0rem" }}><Icon name="Checked" size="20px" color="info800" /> Saved </Button>
                                : isSaved === false ? <Button h="1.75rem" bg="info300" textSize= "1rem" textColor="info800" rounded="lg" m={{ r: ".25rem", l:"0rem", b:".25rem" }} p={{ x: ".75rem", y: "0rem" }}><Icon name="Loading" size="20px" color="info800" /> Saving </Button> : null}
                        {workout.isPublished ?
                            <Button h="1.75rem" bg="success300" textColor="success800" textSize= "1rem" rounded="lg" m={{ r: "0rem", l:"0rem", b:".25rem" }} p={{ x: ".75rem", y: "0rem" }} >READY!</Button>
                            : isAdmin ? <Button h="1.75rem" bg="danger300" textColor="danger800" textSize= "1rem" rounded="lg" m={{ r: "0rem", l:"0rem", b:".25rem" }} p={{ x: ".75rem", y: "0rem" }}>UNPUBLISHED</Button> : null}
                    </Div>
                </Div>
                <Collapse ref={nodeRef} isOpen={showCollapse} border={{ t: "1px solid" }} borderColor="gray400" h="100% important!">
                    <Div h="1rem"></Div>
                    <Textarea readOnly={!isAdmin} textSize="16px" onChange={debounce((e) => isAdmin ? upsertWorkout("content", e.target.value) : null, 300)} defaultValue={(workout.isPublished || isAdmin) ? workout.content : ""} style={{ resize: "none" }} placeholder="Workout to come!" />
                    
                    <ConditionalInput field="pace" type="text" promptField="promptPace" placeholder="Pace" workout={workout} setWorkout={setWorkout} isAdmin={isAdmin} upsertWorkout={upsertWorkout} />
                    <ConditionalInput field="elevationGain" type="text" promptField="promptElevationGain" placeholder="Elevation Gain" workout={workout} setWorkout={setWorkout} isAdmin={isAdmin} upsertWorkout={upsertWorkout} />
                    <ConditionalInput field="splits" type="text" promptField="promptSplits" placeholder="Splits" workout={workout} setWorkout={setWorkout} isAdmin={isAdmin} upsertWorkout={upsertWorkout} />
                    <ConditionalInput field="milesToRun" type="number" promptField="promptMilesToRun" placeholder="Miles to Run" workout={workout} setWorkout={setWorkout} isAdmin={isAdmin} upsertWorkout={upsertWorkout} />
                    <ConditionalInput field="milesRan" type="number" promptField="promptMilesRan" placeholder="Miles Ran" workout={workout} setWorkout={setWorkout} isAdmin={isAdmin} upsertWorkout={upsertWorkout} />
                    
                    {
                        workout.comments.map((comment) => (<Div m={{ x: "1rem" }} key={"comment-" + comment.batch}>
                            <Row>
                                <Col d="flex">
                                    <Text m={{ l: {xs:"1rem",xl:".75rem"}, t: {xs:".5rem",xl: ".5rem"}}} textColor="brand100" textSize="1rem" style={{ fontStyle: "italic" }}>{comment.commenter}</Text>
                                    <Text m={{ l: {xs:".5rem",xl:".25rem"}, t: {xs:".75rem",xl: ".75rem"}}} textColor="gray600" textSize=".75rem" style={{ fontStyle: "italic" }}> {moment(parseInt(comment.timestamp)).format("M/D/YY h:mm A")}</Text>
                                </Col>
                            </Row>
                            <Row >
                                <Col>
                                    <Textarea readOnly defaultValue={comment.content} style={{ resize: "none" }} />
                                </Col>
                            </Row>
                        </Div>))
                    }
                    <form onSubmit={(e) => { e.preventDefault(); publishComment(e); return false; }}>
                        <input type="hidden" name="batch" value={workout.batch} />
                        <input type="hidden" name="index" value={workout.index} />
                        <Textarea textSize="16px" name="comment" m={{ t: "1rem" }} style={{ resize: "none" }} placeholder="How did the run go?" />
                        <Button type="submit" h="2.5rem" bg="brand500" hoverBg="black800" rounded="lg" m={{ t: ".5rem", r: "1rem" }} textColor="white" suffix={
                            <Icon name="MessageSolid" size="20px" color="white" m={{ l: ".5rem" }} />
                        }>
                            Send
                        </Button>
                    </form>

                </Collapse>
            </Div>
        </Col>
    )
};

export default WorkoutTab;