import React, { useEffect, useState } from "react";
import {
    Button,
    Div,
    Dropdown
} from "atomize";
import { API } from "aws-amplify";
import {
    getAccessToken,
    assembleUser
} from "../helpers/userFunctions";
// import shoes from '../images/marmon.jpeg';


const UserDropDown = ({isAdmin, userBeingViewed, setUserBeingViewed}) => {


    const [showUserDropdown, setShowUserDropdown] = useState(false);
    const [userList, setUserList] = useState(<Div></Div>);

    useEffect(() => {
        if (isAdmin) {
            getUserList(setUserList, setUserBeingViewed);
        }
    }, [isAdmin]);

    async function getUserList(setUserList, setUserBeingViewed) {
        console.log("getting user list")
        
        let apiName = 'AdminQueries';
        let path = '/listUsers';
        let myInit = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `${(await getAccessToken()).getJwtToken()}`
            }
        }
        const users = (await API.get(apiName, path, myInit)).Users;
        setUserList(
            <Div>
                {users.map((user) => (
                <Button key={user.Attributes.find((item) => item.Name == "sub").Value + "::" + user.Username} onClick={() => {setUserBeingViewed(assembleUser(user)); setShowUserDropdown(false)}} d="block" bg="transparent" textColor="info700" hoverTextColor="info800" m={{ r: "1rem" }} w="100%">
                    {user.Username}
                </Button>
                ))}
            </Div>
        );
    }

    return (
        <Dropdown isOpen={showUserDropdown} onClick={() => setShowUserDropdown(!showUserDropdown) } menu={userList} m={{ t: "3rem" }} isLoading={userBeingViewed.name == undefined}>
            {(userBeingViewed.name ?? "").split('::')[1]}
        </Dropdown>            
    );
};

export default UserDropDown;