import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { StyleReset, ThemeProvider } from 'atomize';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider as StyletronProvider } from "styletron-react";
import { Client as Styletron } from "styletron-engine-atomic";

import { Amplify } from 'aws-amplify';
import config from './aws-exports';
Amplify.configure(config);

const theme = {
  fontFamily: {
   primary: "Roboto-Regular",
  },
  colors: {
    brand100: "#ffa29b",
    brand200: "#696047",
    brand300: "#18020C",
    brand400: "#FEEFE5",
    brand500: "#568EA3",
  },
  grid: {
    colCount: 7,
    containerMaxWidth: {
      xs: "540px",
      sm: "720px",
      md: "960px",
      lg: "1156px",
      xl: "1400px"
    },
    gutterWidth: "0px",
  }
};

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <StyletronProvider value={new Styletron()}>
    <ThemeProvider theme={theme}>
      <StyleReset />
      <App />
    </ThemeProvider>
  </StyletronProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
