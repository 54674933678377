import React, { useEffect, useState } from "react";
import NavBar from "../components/navbar";
import UserDropDown from "../components/userDropdown";
import {
    Container,
    Row,
    Col,
    Text,
    Div,
    Textarea,
    Button,
    Icon,
    Input
} from "atomize";
import {
    getCurrentUser,
    isUserAdmin,
    customAttributes
} from "../helpers/userFunctions";
import { Auth } from "aws-amplify";
import {
    withAuthenticator,
} from "@aws-amplify/ui-react";
import { LoginHeader } from "../components/loginHeader";


const UserInfo = ({ signOut })  => {

    const [isAdmin, setIsAdmin] = useState(false);
    const [userBeingViewed, setUserBeingViewed] = useState({});

    const isLoadingUser = Object.keys(userBeingViewed).length == 0;

    useEffect(() => {
        isUserAdmin(setIsAdmin);
        getCurrentUser(setUserBeingViewed);
    }, []);

    async function publishAttributes(event) {

        event.preventDefault(); 

        const userAttributesFromForm = {};
        customAttributes.forEach(customAttributeName => userAttributesFromForm[`custom:${customAttributeName}`] = event.target.elements[customAttributeName].value ?? "");
        
        customAttributes.forEach(customAttributeName => userBeingViewed[customAttributeName] = userAttributesFromForm[`custom:${customAttributeName}`]);
        setUserBeingViewed(userBeingViewed);

        if (Object.keys(userAttributesFromForm).some(key => userAttributesFromForm[key].length >= 2048)) {
            alert("One or more elements are longer than the max-length of 2048 characters.")
        } else {
            const user = await Auth.currentAuthenticatedUser();
            await Auth.updateUserAttributes(user, userAttributesFromForm);
        }

        return false;
    }

    const UserInfoTitle = ({ title })  => {
        return (
            <Text w={{xs: "100%", xl:"50%"}} m={{x:"auto",t:"1rem", b: ".5rem"}} textAlign="center" textColor="brand300" fontFamily="Montserrat-Medium" textSize="paragraph" textWeight="800">
                {title}
            </Text>
        )
    }

    const UserInfoInputRow = ({ title, inputName, isLoadingUser })  => {
        return (
            <>
                <Row>
                    <Col>
                        <UserInfoTitle title={title} />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Input name={inputName} defaultValue={userBeingViewed[inputName]} isLoading={isLoadingUser} />
                    </Col>
                </Row>
            </>
        )
    }

    const UserInfoTextareaRow = ({ title, inputName, isLoadingUser })  => {
        return (
            <>
                <Row>
                    <Col>
                        <UserInfoTitle title={title} />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Textarea name={inputName} style={{ resize: "none" }} defaultValue={userBeingViewed[inputName]} h="15rem" isLoading={isLoadingUser} />
                    </Col>
                </Row>
            </>
        )
    }

    return (
        <>
            <NavBar signOut={signOut} />
            <form onSubmit={async (e) => { return await publishAttributes(e); }}>
                <Container>
                    
                    <Row>
                        <Col>
                            <Div d="flex" justify="center">
                                <Text tag="h1"  textAlign="center" textColor="brand100" fontFamily="Agbalumo-Regular" textSize="display3" m={{ t: "3rem", b:"1.5rem" }} style={{ lineHeight: "3.3rem" }}>
                                    My Info
                                </Text>
                            </Div>
                        </Col>
                    </Row>
                    {
                        isAdmin ?
                            <Row>
                                <Col>
                                    <UserDropDown isAdmin={isAdmin} userBeingViewed={userBeingViewed} setUserBeingViewed={setUserBeingViewed} />
                                </Col>
                            </Row> 
                        : null
                    }
                    <Row>
                        <Col>
                            <Text w={{xs: "100%", xl:"50%"}} m={{x:"auto",t:"2rem"}} textAlign="center" textColor="gray800" fontFamily="Montserrat-Medium" textSize="paragraph" textWeight="400">
                                <em>In order to help us plan a running program for you, it is useful to evaluate some of your health and lifestyle history, as well as present running fitness. Please answer the following questions to the best of your ability. Your information will be kept confidential and used only in helping make recommendations for a fitness program.</em>
                            </Text>
                        </Col>
                    </Row>
                    
                    <UserInfoInputRow title="Age" inputName="age" isLoadingUser={isLoadingUser}/>
                    <UserInfoInputRow title="Sex" inputName="sex" isLoadingUser={isLoadingUser}/>
                    <UserInfoInputRow title="Phone" inputName="phone" isLoadingUser={isLoadingUser}/>
                    <UserInfoInputRow title="Emergency Contact" inputName="emergencyContact" isLoadingUser={isLoadingUser}/>
                    <UserInfoInputRow title="Health Risks" inputName="healthRisks" isLoadingUser={isLoadingUser}/>
                    <UserInfoInputRow title="(yes/no) I run for fitness and fun" inputName="interestFitnessFun" isLoadingUser={isLoadingUser}/>
                    <UserInfoInputRow title="(yes/no) I run for recreational or social racing" inputName="interestRacing" isLoadingUser={isLoadingUser}/>
                    <UserInfoInputRow title="(yes/no) I run to train for multi-sport" inputName="interestTraining" isLoadingUser={isLoadingUser}/>
                    <UserInfoInputRow title="(yes/no) I run to improve my racing performance" inputName="interestPerformance" isLoadingUser={isLoadingUser}/>
                    <UserInfoInputRow title="(yes/no) I run for awards (overall, age group, BQ, etc." inputName="interestAwards" isLoadingUser={isLoadingUser}/>
                    <UserInfoTextareaRow title="Other reasons I run" inputName="runningInterestOther" isLoadingUser={isLoadingUser}/>
                    <UserInfoInputRow title="How long have you been running?" inputName="howLongRunning" isLoadingUser={isLoadingUser}/>
                    <UserInfoInputRow title="Would you consider yourself a novice or experienced runner?" inputName="noviceOrExperienced" isLoadingUser={isLoadingUser}/>
                    <UserInfoTextareaRow title="How would you describe your current level of fitness? What non-running fitness activities do you regularly do?" inputName="currentLevelFitness" isLoadingUser={isLoadingUser}/>
                    <UserInfoTextareaRow title="What goals do you want to accomplish while working together?" inputName="goals" isLoadingUser={isLoadingUser}/>
                    <UserInfoInputRow title="How many miles per week have you averaged over the past three months?" inputName="lastThreeMonthsMiles" isLoadingUser={isLoadingUser}/>
                    <UserInfoInputRow title="Have you ever done “speed” workouts, interval training, or “effort sessions”? (yes/no)" inputName="doneSpeed" isLoadingUser={isLoadingUser}/>
                    <UserInfoTextareaRow title="Provide details of any speed, interval, or effort sessions (frequency, workout, etc.)" inputName="doneSpeedDetails" isLoadingUser={isLoadingUser}/>
                    <UserInfoInputRow title="Do you have any racing experience?" inputName="racingExperience" isLoadingUser={isLoadingUser}/>
                    <UserInfoInputRow title="Describe your most recent racing results. Include distance, pace/time, and date." inputName="racingDetails" isLoadingUser={isLoadingUser}/>
                    <UserInfoInputRow title="Please describe your running personal bests in the mile/1500, 5k, 10k, half-marathon, marathon, etc." inputName="runningPersonalBests" isLoadingUser={isLoadingUser}/>
                    <UserInfoTextareaRow title="Describe any problems with previous racing or training." inputName="problems" isLoadingUser={isLoadingUser}/>
                    <UserInfoInputRow title="What types of workouts would give you confidence that you are ready for your goal race (if applicable)" inputName="workoutTypes" isLoadingUser={isLoadingUser}/>
                    <UserInfoInputRow title="Do you get easily bored with workouts or is repetition okay? How often do you like to switch it up?" inputName="easilyBored" isLoadingUser={isLoadingUser}/>
                    <UserInfoTextareaRow title="Is there anything else not covered in this questionnaire that I should know?" inputName="anythingElse" isLoadingUser={isLoadingUser}/>

                    <Row>
                        <Col>
                                <Button type="submit" h="2.5rem" bg="brand500" hoverBg="black800" rounded="lg" m={{ t: ".5rem", r: "1rem" }} textColor="white" suffix={
                                    <Icon name="Checked" size="20px" color="white" m={{ l: ".5rem" }} />
                                }>
                                    Save
                                </Button>
                        </Col>
                    </Row>
                    <br />
                    <br />
                    <br />
                </Container>
            </form>
        </>
    );
};

export default withAuthenticator(UserInfo, { components: { Header: LoginHeader }});