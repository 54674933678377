import React, { useEffect, useState } from "react";
import {
    Text,
    Row,
    Col,
    Container,
    Icon,
    Button,
    Div
} from "atomize";
import { API } from "aws-amplify";
import { 
    listWorkouts as listWorkoutsQuery, 
    listComments as listCommentsQuery,
} from "../graphql/queries";

import WorkoutTab from '../components/workoutTab'
import moment from 'moment';
import {
    createWorkout as createWorkoutMutation,
    updateWorkout as updateWorkoutMutation,
    sendEmail
} from "../graphql/mutations";
import {
    withAuthenticator,
} from "@aws-amplify/ui-react";
import { LoginHeader } from "../components/loginHeader";
import NavBar from "../components/navbar";
import {
    getCurrentUser,
    isUserAdmin
} from "../helpers/userFunctions";
import UserDropDown from "../components/userDropdown";

const Workouts = ({ signOut }) => {

    const [currentBatch, setCurrentBatch] = useState(getLastMondayString(new Date()));
    const [loadingWorkouts, setLoadingWorkouts] = useState(true);
    const [isAdmin, setIsAdmin] = useState(false);
    const [userBeingViewed, setUserBeingViewed] = useState({});
    const [workouts, setWorkouts] = useState([]);
    const [botheredEmily, setBotheredEmily] = useState(false);
    
    useEffect(() => {
        isUserAdmin(setIsAdmin);
        getCurrentUser(setUserBeingViewed);
    }, []);

    useEffect(() => {
        if (currentBatch && Object.keys(userBeingViewed).length !== 0) {
            getWorkoutsByOwner(userBeingViewed.name);
        }
    }, [currentBatch, userBeingViewed]);

    async function getWorkoutsByOwner(owner) {

        console.log(`Loading batch ${currentBatch} for ${owner}`);
        setLoadingWorkouts(true);
        const workoutApiData = await API.graphql({ 
            query: listWorkoutsQuery,
            authMode: "AMAZON_COGNITO_USER_POOLS",
            variables: {
                owner,
                batch: {
                    beginsWith: currentBatch
                }
            }
        });
        const rawWorkouts = workoutApiData.data.listWorkouts.items;

        const commentApiData = await API.graphql({ 
            query: listCommentsQuery,
            authMode: "AMAZON_COGNITO_USER_POOLS",
            variables: {
                owner,
                batch: {
                    beginsWith: currentBatch
                }
            }
        });
        const comments = commentApiData.data.listComments.items;

        const workouts = [];
        [...Array(7).keys()].map((dayOfWeek) => {
            var date = buildDateInUserTzFromIsoString(currentBatch);
            date.add(dayOfWeek, 'days');
            const dateString = getDateString(date);
            const batchKey = currentBatch + "#" + dateString;
            const workout = rawWorkouts.find(w => w.batch === batchKey) ?? {
                batch: batchKey,
                content: "",
                date: dateString,
            }
            workout.comments = comments.filter(c => c.batch.startsWith(batchKey)) ?? [];
            workout.comments.sort((a, b) => parseInt(a.timestamp) - parseInt(b.timestamp))
            workout.index = dayOfWeek;
            workout.showAddComment = false;
            workouts.push(workout);
        });

        console.log(workouts);
        setWorkouts(workouts);
        setLoadingWorkouts(false);
    }

    async function upsertWorkout(field, value, date) {
        console.log(`Upserting: ${field}=${value} for ${date}`)
        const upsert = {
            variables: {
                input: {
                    owner: userBeingViewed.name,
                    batch: currentBatch + "#" + date,
                    date: date,
                    [field]: value
                }
            },
            authMode: "AMAZON_COGNITO_USER_POOLS"
        }
    
        // try to update and create if failed
        try {
            await API.graphql({ ...upsert, query: updateWorkoutMutation });
        } catch (e) {
            if (e.errors.find((error) => error.errorType == 'DynamoDB:ConditionalCheckFailedException' || error.errorType == 'Unauthorized') !== undefined) {
                await API.graphql({ ...upsert, query: createWorkoutMutation });
            } else {
                throw e;
            }
        }

    }

    function getDateString(d) {
        return d.format().split('T')[0];
    }

    function getLastMondayString(d) {
        var t = moment(d).startOf('isoWeek'); // get Monday in users tz
        return t.format().split('T')[0]; // remove all tz details by converting to string which is same in any tz
    }

    function buildDateInUserTzFromIsoString(isoString) {
        return moment(isoString);
    }

    function incrementCurrentBatch(increment) { 
        const currentBatchDate = buildDateInUserTzFromIsoString(currentBatch);
        currentBatchDate.add(increment, 'd');
        setCurrentBatch(getDateString(currentBatchDate));
    }

    function getDateRangeText() {
        const currentBatchDate = buildDateInUserTzFromIsoString(currentBatch);
        const endOfCurrentBatchDate = moment(currentBatchDate);
        endOfCurrentBatchDate.add(6, 'd');
        return `${currentBatchDate.format("MMM D")} - ${endOfCurrentBatchDate.format("MMM D")}`;
    }

    async function publishBatch() {
        await Promise.all([...Array(7).keys()].map((dayOfWeek) => {
            var date = buildDateInUserTzFromIsoString(currentBatch);
            date.add(dayOfWeek, 'd');
            const dateString = getDateString(date);
            return upsertWorkout("isPublished", true, dateString);
        }));
        getWorkoutsByOwner(userBeingViewed.name);
        // notifyUser();
    }

    async function botherEmily() {
        const username = userBeingViewed.name.split('::')[1];
        await API.graphql({
            variables: {
                message: `${username} updated their workouts!`,
                recipient: "eachter95@gmail.com",
                subject: `${username} Activity Notification`
            },
            query: sendEmail,
            authMode: "AMAZON_COGNITO_USER_POOLS"
        });
        console.log("bothered emily");
        setBotheredEmily(true);
    }

    async function notifyUser() {
        await API.graphql({
            variables: {
                message: 'Get after it!',
                recipient: userBeingViewed.email,
                subject: `Weekly Workouts Published`
            },
            query: sendEmail,
            authMode: "AMAZON_COGNITO_USER_POOLS"
        });
    }

    return (
        <>
            <NavBar signOut={signOut} />
            <Container p={{x:".75rem"}}>
                {
                    isAdmin ?
                        <Row>
                            <Col>
                                <UserDropDown isAdmin={isAdmin} userBeingViewed={userBeingViewed} setUserBeingViewed={setUserBeingViewed} />
                                <Div d="flex" justify="center" w="100%">
                                    <Button
                                        suffix={
                                            <Icon name="RightUp" size="16px" color="white" m={{ l: "1rem" }} />
                                        }
                                        shadow="3"
                                        hoverShadow="4"
                                        bg="brand500"
                                        hoverBg="black800"
                                        rounded="circle"
                                        m={{ r: "1rem", t: "1rem" }}
                                        onClick={publishBatch}
                                    >
                                        Publish!
                                    </Button>
                                </Div>
                            </Col>
                        </Row> 
                    : null
                }
                <Row>
                    <Col>
                        <Div d="flex" justify="center" p={{t: "3rem",b: "1rem"}}>
                            <Icon onClick={() => incrementCurrentBatch(-7)} name="Back" size="2.5rem" m={{t:".25rem", r: "1rem"}} color="brand300" hoverColor="black400" /> 
                            <Text textSize="heading" textWeight="bold" textColor="brand300">{getDateRangeText()}</Text>
                            <Icon onClick={() => incrementCurrentBatch(7)} name="Next" size="2.5rem" m={{t:".25rem", l: "1rem"}} color="brand300" hoverColor="black400" />
                        </Div>
                    </Col>
                </Row>
                {
                    workouts.some(workout => workout.milesToRun) || workouts.some(workout => workout.milesRan) ?
                    <Row>
                        <Col>
                            <Div d="flex" justify="center" p={{b: "1rem"}}>
                                { workouts.some(workout => workout.milesToRun) ? <Text textSize="subheader" textColor="gray800" m={{r: ".5rem"}}><em>Miles to Run: <strong>{+parseFloat(workouts.reduce((sum, workout) => !isNaN(workout.milesToRun) ? sum + workout.milesToRun : sum , 0)).toFixed(2)}</strong></em></Text> : null }
                                { workouts.some(workout => workout.milesRan) ? <Text textSize="subheader" textColor="gray800"><em>Miles Ran:  <strong>{+parseFloat(workouts.reduce((sum, workout) => !isNaN(workout.milesRan) ? sum + workout.milesRan : sum , 0)).toFixed(2)}</strong></em></Text> : null }
                            </Div>
                        </Col>
                    </Row>
                    : null
                }
                <Row>
                    {
                        loadingWorkouts ?
                            <Col>
                                <Div d="flex" justify="center" p={{y: "1rem"}}>
                                    <Icon name="Loading" color="medium" size="2rem" m={{r:".25rem"}}/><Text textSize="1.5rem" textColor="medium" style={{lineHeight: "2rem", verticalAlign:"middle"}}>Loading workouts... </Text> 
                                </Div>
                            </Col>
                        : workouts.map((workout) => {
                            return ( 
                                <WorkoutTab key={"workout-" + workout.batch} workoutInput={workout} isAdmin={isAdmin} userBeingViewed={userBeingViewed} />
                            )}
                        )
                    }
                </Row>
                {
                    workouts.some(workout => workout.isPublished) ?
                    <Row>
                        <Col>
                            <Div d="flex" justify="center" w="100%">
                                <Button
                                    suffix={
                                        botheredEmily ? 
                                        <Icon name="Checked" size="16px" color="white" m={{ l: "1rem" }} />
                                        : <Icon name="RightUp" size="16px" color="white" m={{ l: "1rem" }} />
                                    }
                                    shadow="3"
                                    bg="brand500"
                                    rounded="circle"
                                    m={{ b: "2rem" }}
                                    onClick={botherEmily}
                                    disabled={botheredEmily}
                                >
                                    Complete Week
                                </Button>
                            </Div>
                        </Col>
                    </Row>
                    : null
                }
            </Container>
        </>
    );
};

export default withAuthenticator(Workouts, { components: { Header: LoginHeader }});