import { Button, SideDrawer, Icon, Text, Row, Anchor } from "atomize";

const Menu = ({ isOpen, onClose, signOut }) => {
    return (
        <SideDrawer isOpen={isOpen} onClose={onClose}>
            <Row justify="right" border={{ b: "1px solid"  }} borderColor="gray400"  p={{ b: "2rem" }}>
                <Button bg="transparent">
                    <Icon name="Cross" size="2rem" hoverColor="gray800" onClick={onClose}/>
                </Button>
            </Row>
            {
                signOut ? 
                <>
                    <Row p={{ t: "1rem", b:"1rem" }} border={{ b: "1px solid" }} borderColor="gray200" justify="center">
                        <Anchor href="/workouts" w="100%">
                            <Button bg="gray200" textColor="medium" m={{ r: "1rem" }} w="100%" >
                                Workouts
                            </Button>
                        </Anchor>
                    </Row>
                    <Row p={{ t: "1rem", b:"1rem" }} border={{ b: "1px solid" }} borderColor="gray200" justify="center">
                        <Anchor href="/user-info" w="100%">
                            <Button bg="gray200" textColor="medium" m={{ r: "1rem" }} w="100%" >
                                My Info
                            </Button>
                        </Anchor>
                    </Row> 
                </>
            : null
            }
            <Row p={{ t: "1rem", b:"1rem" }} border={{ b: "1px solid"  }} borderColor="gray200" justify="center">
                {
                    signOut ?
                        <Button onClick={signOut} bg="gray200" textColor="medium" w="100%" >
                            Sign Out
                        </Button>
                    : 
                        <Anchor href="/workouts" w="100%">
                            <Button bg="gray200" textColor="medium" m={{ r: "1rem" }} w="100%" >
                                Sign In
                            </Button>
                        </Anchor>
                }
            </Row>
            <Row p={{ t: "1rem", b:"1rem" }} border={{ b: "1px solid" }} borderColor="gray400" justify="center">
                <Anchor href="/" w="100%">
                    <Button bg="gray200" textColor="medium" m={{ r: "1rem" }} w="100%" >
                        Home
                    </Button>
                </Anchor>
            </Row>
        </SideDrawer>
    );
};

export default Menu;