import React from "react";
import {
    Button,
    Div
} from "atomize";
import { Auth, API } from "aws-amplify";

export const customAttributes = [
    "age",
    "anythingElse",
    "currentLevelFitness",
    "doneSpeed",
    "doneSpeedDetails",
    "easilyBored",
    "emergencyContact",
    "goals",
    "healthRisks",
    "howLongRunning",
    "interestAwards",
    "interestFitnessFun",
    "interestPerformance",
    "interestRacing",
    "interestTraining",
    "lastThreeMonthsMiles",
    "noviceOrExperienced",
    "phone",
    "problems",
    "racingDetails",
    "racingExperience",
    "runningInterestOther",
    "runningPersonalBests",
    "sex",
    "workoutTypes"
]

export async function getCurrentUser(setUserBeingViewed) {
    const currentOwner = await Auth.currentUserInfo();
    const userBeingViewed = {
        name: currentOwner.attributes.sub + "::" + currentOwner.username, 
        email: currentOwner.attributes.email,
    }
    customAttributes.forEach(customAttributeName => userBeingViewed[customAttributeName] = currentOwner.attributes[`custom:${customAttributeName}`] ?? "");
    setUserBeingViewed(userBeingViewed);
} 

export async function isUserAdmin(setIsAdmin) {
    const userGroups = (await getAccessToken()).payload['cognito:groups'];
    const isUserAdmin = userGroups != undefined && userGroups.includes("Admin");
    console.log(`Current user is admin: ${isUserAdmin}`);
    setIsAdmin(isUserAdmin);
}

export async function getAccessToken() {
    return (await Auth.currentSession()).getAccessToken();
}

export function assembleUser(userInfo) {
    
    const user = {
        name: userInfo.Attributes.find((item) => item.Name == "sub").Value + "::" + userInfo.Username,
        email: userInfo.Attributes.find((item) => item.Name == "email").Value,
    };
    customAttributes.forEach(customAttributeName => user[customAttributeName] = userInfo.Attributes.find((item) => item.Name == `custom:${customAttributeName}`)?.Value ?? "");

    return user;
}