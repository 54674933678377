/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getWorkout = /* GraphQL */ `
  query GetWorkout($owner: String!, $batch: String!) {
    getWorkout(owner: $owner, batch: $batch) {
      owner
      batch
      date
      content
      isPublished
      promptPace
      pace
      promptElevationGain
      elevationGain
      promptSplits
      splits
      promptMilesToRun
      milesToRun
      promptMilesRan
      milesRan
      title
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listWorkouts = /* GraphQL */ `
  query ListWorkouts(
    $owner: String
    $batch: ModelStringKeyConditionInput
    $filter: ModelWorkoutFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listWorkouts(
      owner: $owner
      batch: $batch
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        owner
        batch
        date
        content
        isPublished
        promptPace
        pace
        promptElevationGain
        elevationGain
        promptSplits
        splits
        promptMilesToRun
        milesToRun
        promptMilesRan
        milesRan
        title
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getComment = /* GraphQL */ `
  query GetComment($owner: String!, $batch: String!) {
    getComment(owner: $owner, batch: $batch) {
      owner
      batch
      commenter
      content
      timestamp
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listComments = /* GraphQL */ `
  query ListComments(
    $owner: String
    $batch: ModelStringKeyConditionInput
    $filter: ModelCommentFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listComments(
      owner: $owner
      batch: $batch
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        owner
        batch
        commenter
        content
        timestamp
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
