// Show Hide Password
import { Input, Button, Icon, Text } from "atomize";
import debounce from "lodash.debounce";

const ConditionalInput = ({ field, type, promptField, placeholder, workout, isAdmin, upsertWorkout, setWorkout }) => {

    return (
        isAdmin || (workout[promptField] && workout.isPublished) ?
        <Input
            m={{t: "1rem"}}
            textSize="16px"
            placeholder={isAdmin ? placeholder : undefined}
            defaultValue={workout[field]}
            onChange={
                debounce((e) => {
                    let value = e.target.value;
                    if (type == "number") {
                        if (isNaN(parseFloat(e.target.value)) && e.target.value != '') {
                            return null;
                        }
                        value = value || 0;
                    }
                    upsertWorkout(field, value);
                }, 300)
            }
            type={type}
            suffix={
                isAdmin ?
                <Button
                    pos="absolute"
                    onClick={() => {
                        workout[promptField] = !workout[promptField];
                        setWorkout(workout);
                        upsertWorkout(promptField, workout[promptField]); 
                    }}
                    bg="transparent"
                    w="3rem"
                    top="0"
                    right="0"
                    rounded={{ r: "md" }}
                >
                    <Icon
                        name={workout[promptField] ? "CBChecked" : "CBUnchecked"}
                        color={workout[promptField] ? "info800" : "info800"}
                        size="16px"
                    />
                </Button>
                : <Text textColor="gray500" pos="absolute" right="1rem" top="50%" transform="translateY(-50%)">{placeholder}</Text> 
            }
        />
        : null
    );
}

export default ConditionalInput
