/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createWorkout = /* GraphQL */ `
  mutation CreateWorkout(
    $input: CreateWorkoutInput!
    $condition: ModelWorkoutConditionInput
  ) {
    createWorkout(input: $input, condition: $condition) {
      owner
      batch
      date
      content
      isPublished
      promptPace
      pace
      promptElevationGain
      elevationGain
      promptSplits
      splits
      promptMilesToRun
      milesToRun
      promptMilesRan
      milesRan
      title
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateWorkout = /* GraphQL */ `
  mutation UpdateWorkout(
    $input: UpdateWorkoutInput!
    $condition: ModelWorkoutConditionInput
  ) {
    updateWorkout(input: $input, condition: $condition) {
      owner
      batch
      date
      content
      isPublished
      promptPace
      pace
      promptElevationGain
      elevationGain
      promptSplits
      splits
      promptMilesToRun
      milesToRun
      promptMilesRan
      milesRan
      title
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteWorkout = /* GraphQL */ `
  mutation DeleteWorkout(
    $input: DeleteWorkoutInput!
    $condition: ModelWorkoutConditionInput
  ) {
    deleteWorkout(input: $input, condition: $condition) {
      owner
      batch
      date
      content
      isPublished
      promptPace
      pace
      promptElevationGain
      elevationGain
      promptSplits
      splits
      promptMilesToRun
      milesToRun
      promptMilesRan
      milesRan
      title
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createComment = /* GraphQL */ `
  mutation CreateComment(
    $input: CreateCommentInput!
    $condition: ModelCommentConditionInput
  ) {
    createComment(input: $input, condition: $condition) {
      owner
      batch
      commenter
      content
      timestamp
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateComment = /* GraphQL */ `
  mutation UpdateComment(
    $input: UpdateCommentInput!
    $condition: ModelCommentConditionInput
  ) {
    updateComment(input: $input, condition: $condition) {
      owner
      batch
      commenter
      content
      timestamp
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteComment = /* GraphQL */ `
  mutation DeleteComment(
    $input: DeleteCommentInput!
    $condition: ModelCommentConditionInput
  ) {
    deleteComment(input: $input, condition: $condition) {
      owner
      batch
      commenter
      content
      timestamp
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const sendEmail = /* GraphQL */ `
  mutation SendEmail($message: String, $recipient: String, $subject: String) {
    sendEmail(message: $message, recipient: $recipient, subject: $subject)
  }
`;
