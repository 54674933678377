import {
    Div,
    Text,
    Container,
    Button, 
    Anchor, 
    Icon
} from "atomize";
// import shoes from '../images/marmon.jpeg';
import React, { useState } from "react";
import Menu from './menu';


const NavBar = ({signOut, backgroundOffset}) => {
    const [showSideDrawer, setShowSideDrawer] = useState(false);
    return (
        <>
            <Div m={{ b: "6rem" }}>
            <Container m="0rem" maxW="100%" d="flex" h="6rem" justify="space-between" pos="fixed" top="0" left="0" right="0" bg="white" border={{b: "1px solid"}} borderColor="gray300" style={{ zIndex: 999}} shadow={4}>
                <Div m={{l:"0rem", t:".75rem"}}>
                    <Anchor href="/" w="100%">
                        <Text textSize="display3" textColor="brand100" fontFamily="Agbalumo-Regular">
                            E.R.C.
                        </Text>
                    </Anchor>
                </Div>
                <Div m={{r:"0rem", t:"1rem"}}>
                    <Button bg="transparent">
                        <Icon name="Menu" size="2rem" hoverColor="gray800" m={{t:".75rem"}} onClick={() => setShowSideDrawer(true) }/>
                    </Button>
                </Div>
            </Container>
            </Div>
            {/* <Div bgImg={shoes} bgSize="cover" bgPos="top" pos="fixed" top={ backgroundOffset ? backgroundOffset : "6rem"}  w="100%" h="100%" style={{ zIndex: -1, opacity:0.0}} /> */}
            <Menu signOut={signOut} isOpen={showSideDrawer} onClose={() => setShowSideDrawer(false) } />
        </>
    );
};

export default NavBar;