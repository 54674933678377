import React from "react";
import NavBar from "../components/navbar";
import {
    Container,
    Row,
    Col,
    Text,
    Div,
    Image
} from "atomize";
import emily from '../images/emily.jpeg';

const Home = () => {

    const AlexRow = ({ title, text }) => {
        return (
            <Row>
                <Col size={3}>
                    <Text m={{ r: ".5rem" }} textAlign="right" textWeight="900" textColor="brand300" fontFamily="Montserrat-Medium" textSize="paragraph" >
                        {title}:
                    </Text>
                </Col>
                <Col size={4}>
                    <Text textAlign="left" textColor="brand300" fontFamily="Montserrat-Medium" textSize="paragraph" >
                        {text}
                    </Text>
                </Col>
            </Row>
        )
    }

    const AlexBigRow = ({ title, text }) => {
        return (<>
            <Row>
                <Text w={{ xs: "100%", xl: "50%" }} m={{ x: "auto", t: "1.5rem", b: ".5rem" }} textAlign="center" textColor="brand100" fontFamily="Agbalumo-Regular" textSize="2rem">
                    {title}
                </Text>
            </Row>
            <Row>
                <Text w={{ xs: "100%", xl: "50%" }} m={{ x: "auto", t: ".5rem", b: "1rem" }} textAlign="center" textColor="brand300" fontFamily="Montserrat-Medium" textSize="paragraph">
                    {text}
                </Text>
            </Row>
        </>);
    }


    return (
        <>
            <NavBar backgroundOffset="6rem" />
            <Container>
                <Row>
                    <Col>
                        <Div d="flex" justify="center">
                            <Text tag="h1" textAlign="center" textColor="brand100" fontFamily="Agbalumo-Regular" textSize="display3" m={{ t: "3rem", b: "3rem" }} style={{ lineHeight: "3.3rem" }}>
                                Emily Running Coaching
                            </Text>
                        </Div>
                    </Col>
                </Row>
                <Row>
                    <Text w={{ xs: "100%", xl: "50%" }} m={{ x: "auto", t: "2rem", b: "4rem" }} textAlign="center" textColor="brand300" fontFamily="Montserrat-Medium" textSize="paragraph">
                        <span style={{ fontFamily: "Agbalumo-Regular", fontSize: "2rem", color: "#ffa29b", lineHeight: "1rem" }}>“</span>
                        <em>Eat pasta, run fasta. They don't think it be like it is, but it do.</em>
                        <span style={{ fontFamily: "Agbalumo-Regular", fontSize: "2rem", color: "#ffa29b", lineHeight: "1rem" }}> ”</span>
                    </Text>
                </Row>
                <Row>
                    <Col>
                        <Div w={{ xs: "100%", xl: "50%" }} justify="center" rounded="xl" border="1px solid" borderColor="gray300" shadow="4" bg="rgba(255, 255, 255, 1)" p={{ x: "1rem", y: "1.5rem" }} m={{ x: "auto", b: "1rem" }}>
                            <Div w="35%" bgImg={emily} bgSize="cover" rounded="circle" bgRepeat="no-repeat" m="0 auto">
                                <Image src={emily} style={{ visibility: "hidden" }} />
                            </Div>
                            <Text textAlign="center" tag="h1" textColor="brand100" fontFamily="Agbalumo-Regular" textSize="2.5rem" m={{ t: "1.5rem", b: "0rem" }}>
                                Emily Achter
                            </Text>
                            <Text textAlign="center" textColor="brand300" fontFamily="Montserrat-Medium" m={{ t: "0rem" }} p="1rem">
                                Boston based coach Emily Achter has a passion for running and is ready to help you meet your goals - whether they be fitness-related or to race your next personal record!
                            </Text>
                            <AlexRow title="LEVELS" text="Beginner, Intermediate, Advanced" />
                            <AlexRow title="TYPES" text="Road" />
                            <AlexRow title="DISTANCES" text="Mile, 5k/10k/Half Marathon, Marathon" />
                            <AlexRow title="TRAINING PLATFORM" text="Custom website" />
                            <AlexRow title="TRAINING PLAN" text="Individual, customized training plan" />
                            <AlexRow title="COMMUNICATION" text="Unlimited email, unlimited phone, unlimited text" />
                            <AlexRow title="RESPONSE TIME" text="Within 24 hours" />
                            <AlexRow title="LOCATION" text="Virtual" />
                            <Text textAlign="center" textColor="brand100" fontFamily="Agbalumo-Regular" textSize="2rem" m={{ t: "1.5rem", b: "0rem" }}>
                                About Me
                            </Text>
                            <Text textAlign="center" textColor="brand300" fontFamily="Montserrat-Medium" m={{ t: "0rem" }} p="1rem">
                            I am a marathoner from Boston. I went to Boston Unitversity for a bachelors/masters in Epidemiology and Biostats. I am currently working at STATinMED. When I am not working or running, I enjoy spending time with my awesome fiance who's really cool and coded this entire website himself (and totally didn't add this sentence in don't tell Emily :D).
                            </Text>
                        </Div>
                    </Col>
                </Row>
                <AlexBigRow 
                    title="Philosophy"
                    text="My philosophy is to train smart and to listen to your body. As a runner it is important to work on your personal limits in a healthy and manageable way, to push the limits but not overuse and exceed. It is easy to want to add too much volume or speed at one time and burn out or get injured. My coaching philosophy is to get the gains in the long term and to trust the process. Growth is not always fast but training smart and recovering harder is the way to see long term success in running, especially by listening to your body. Taking off a few days is better long term than pushing through and having to take weeks/months off later down the line. My goal is to help the athlete discover how they respond to certain workouts and stressed and to respond and recover."
                />
                <AlexBigRow 
                    title="Coaching Experience"
                    text="I have been an accredited coach since 2022. I have trained athletes from their first 5k to athletes who were looking for a BQ marathon time."
                />
            </Container >
        </>
    );
};

export default Home;