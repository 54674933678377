import { useTheme } from "@aws-amplify/ui-react";
import {
  Div,
  Text,
  Button,
  Anchor
} from "atomize";

export function LoginHeader() {
  const { tokens } = useTheme();

  return (
    <Div d="flex" w="100%" justify="center" m={{b:"4rem"}}>
        <Anchor href="/">
            <Text textColor="brand600" fontFamily="Agbalumo-Regular" textSize="7rem" >
              E.R.C
            </Text>
        </Anchor>
    </Div>
  );
}
