import "@aws-amplify/ui-react/styles.css";
import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import Workouts from "./pages/workouts.js";
import Home from "./pages/home.js"
import UserInfo from "./pages/userInfo.js"

const App = () => {

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/workouts" element={<Workouts/>} />
        <Route path="/user-info" element={<UserInfo/>} />
        <Route path="/" element={<Home/>} />
      </Routes>
    </BrowserRouter>
  );
};

export default App;